import useCanSuperuser from "modules/superuser/hooks/useCanSuperuser";
import React from "react";
import { useSetRecoilState } from "recoil";
import BusinessRep from "reps/BusinessRep";
import UserInvitationRep from "reps/UserInvitationRep";
import businessGuidState from "state/auth/businessGuid";

import {
  BusinessDisambiguatorBusiness,
  BusinessDisambiguatorUserInvitation,
  BusinessDisambiguatorAddNewBusiness,
} from "./BusinessDisambiguatorBusiness";

type Props = {
  businesses: BusinessRep.Complete[];
  userInvitations: UserInvitationRep.Complete[];
};

const BusinessDisambiguatorBusinesses: React.FC<Props> = ({ businesses, userInvitations }) => {
  /**
   * NB(hudson): For now, we're only showing the add new business button to superusers.
   *  It's impractical to create a feature flag for this
   *  because of the nature of the multi-business
   *  combined with the fact that we use business guid as the LaunchDarkly key.
   */
  const addBusinessButtonIsEnabled = useCanSuperuser();

  const setBusinessGuid = useSetRecoilState(businessGuidState);

  const selectBusiness = (businessGuid: string) => {
    setBusinessGuid(businessGuid);
    window.location.reload(); // Intended to force a refresh.
  };

  return (
    <>
      {userInvitations.map((userInvitation) => {
        return (
          <BusinessDisambiguatorUserInvitation
            key={userInvitation.guid}
            userInvitation={userInvitation}
          />
        );
      })}
      {businesses.map((business) => {
        return (
          <BusinessDisambiguatorBusiness
            key={business.guid}
            business={business}
            onSelect={() => selectBusiness(business.guid)}
          />
        );
      })}
      {addBusinessButtonIsEnabled && <BusinessDisambiguatorAddNewBusiness />}
    </>
  );
};

export default BusinessDisambiguatorBusinesses;
