import { captureException } from "@sentry/react";
import { useMutation } from "@tanstack/react-query";
import { useSetRecoilState } from "recoil";
import useUserGuid from "resources/jwt/queries/useUserGuid";
import businessGuidState from "state/auth/businessGuid";
import isSuperuseringState from "state/auth/isSuperusering";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";

/**
 * This hook allows creation of a new business.
 * After the business is created, it will always immediately redirect to onboarding
 * due to the "window.location.href = "/"".
 */
const useCreateNewBusiness = () => {
  const userGuid = useUserGuid();
  const highbeamApi = useHighbeamApi();

  const setIsSuperusering = useSetRecoilState(isSuperuseringState);
  const setBusinessGuid = useSetRecoilState(businessGuidState);

  return useMutation({
    mutationFn: () => highbeamApi.business.create({ ownerUserGuid: userGuid }),
    onSuccess: ({ guid: businessGuid }) => {
      setIsSuperusering(false);
      setBusinessGuid(businessGuid);
      window.location.href = "/"; // Intended to force a refresh.
    },
    onError: (e) => {
      notify("error", "Something went wrong! Please try again.");
      captureException(e);
    },
  });
};

export default useCreateNewBusiness;
