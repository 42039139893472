import useCanSuperuser from "modules/superuser/hooks/useCanSuperuser";
import React, { ReactNode, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import useBusinessesByMemberUser from "resources/businesses/queries/useBusinessesByMemberUser";
import useUserInvitationsByEmailAddress from "resources/user-invitations/queries/useUserInvitationsByEmailAddress";
import businessGuidState from "state/auth/businessGuid";
import isSuperuseringState from "state/auth/isSuperusering";

import BusinessDisambiguatorLayout from "./BusinessDisambiguatorLayout";

type Props = {
  children: ReactNode;
};

/**
 * This component wraps most of the app, adding support for multi-business.
 * It uses some approaches that are atypical in React,
 * such as returning null to avoid flickers and using window.location.href.
 * Avoid copying these patterns.
 */
const BusinessDisambiguator: React.FC<Props> = ({ children }) => {
  const [businessGuid, setBusinessGuid] = useRecoilState(businessGuidState);

  const canSuperuser = useCanSuperuser();
  const [isSuperusering, setIsSuperusering] = useRecoilState(isSuperuseringState);

  const businesses = useBusinessesByMemberUser();
  const userInvitations = useUserInvitationsByEmailAddress();

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(true);
    if (isSuperusering && !canSuperuser) {
      // The user is superusering, but they're not allowed to.
      setIsSuperusering(false);
      return;
    }
    if (
      businessGuid &&
      !businesses.map(({ guid }) => guid).includes(businessGuid) &&
      !isSuperusering
    ) {
      // The user is trying to access a business they don't have access to.
      setBusinessGuid("");
      return;
    }
    if (
      !businessGuid &&
      businesses.length === 1 &&
      userInvitations.length === 0 &&
      !isSuperusering
    ) {
      // Automatically select the business if the user only has 1.
      setBusinessGuid(businesses[0].guid);
      return;
    }
    setIsLoading(false);
  }, [
    businessGuid,
    businesses,
    canSuperuser,
    isSuperusering,
    setBusinessGuid,
    setIsSuperusering,
    userInvitations.length,
  ]);

  if (isLoading) {
    return null;
  }

  if (businessGuid) {
    return <>{children}</>;
  }

  return <BusinessDisambiguatorLayout businesses={businesses} userInvitations={userInvitations} />;
};

export default BusinessDisambiguator;
