import { ArrowRight, Buildings, EnvelopeSimple, PlusCircle } from "@phosphor-icons/react";
import classNames from "classnames";
import useCreateNewBusiness from "modules/business/useCreateNewBusiness";
import React from "react";
import BusinessRep from "reps/BusinessRep";
import UserInvitationRep from "reps/UserInvitationRep";
import useUserGuid from "resources/jwt/queries/useUserGuid";
import useUserRoleMembershipDisplayName from "resources/user-roles/queries/useUserRoleMembershipDisplayName";
import colors from "styles/colors";
import Pill from "ui/data-display/Pill";
import AnimatedSpinner from "ui/feedback/AnimatedSpinner";
import Text from "ui/typography/Text";
import getBusinessSafeDisplayName from "utils/business/getBusinessSafeDisplayName";

import styles from "./BusinessDisambiguatorBusiness.module.scss";

type UserInvitationProps = {
  userInvitation: UserInvitationRep.Complete;
};

export const BusinessDisambiguatorUserInvitation: React.FC<UserInvitationProps> = ({
  userInvitation,
}) => {
  return (
    <div className={styles.business}>
      <Buildings
        size={48}
        color={colors.grey[500]}
        className={classNames(styles.icon, styles.userInvitationIcon)}
      />
      <div className={styles.name}>
        <Text size={16}>
          {getBusinessSafeDisplayName({
            guid: userInvitation.businessGuid,
            displayName: userInvitation.businessDisplayName,
          })}
          &nbsp;(pending invite)
        </Text>
      </div>
      <div className={classNames(styles.right, styles.reSendContainer)}>
        <EnvelopeSimple size={16} color={colors.purple[500]} />
        <Text size={12} weight="medium" color={colors.purple[500]}>
          Re-send email invite
        </Text>
      </div>
    </div>
  );
};

type BusinessProps = {
  business: BusinessRep.Complete;
  onSelect: () => void;
};

export const BusinessDisambiguatorBusiness: React.FC<BusinessProps> = ({
  business,
  onSelect: handleSelect,
}) => {
  const userRoleMembershipDisplayName = useUserRoleMembershipDisplayName(
    business.guid,
    useUserGuid()
  );

  return (
    <div role="button" tabIndex={0} className={styles.business} onClick={handleSelect}>
      <Buildings
        size={48}
        color={colors.purple[500]}
        className={classNames(styles.icon, styles.businessIcon)}
      />
      <div className={styles.name}>
        <Text size={16}>{getBusinessSafeDisplayName(business)}</Text>
        <Pill size={20} color="grey-light">
          {userRoleMembershipDisplayName}
        </Pill>
      </div>
      <ArrowRight size={24} color={colors.purple[500]} className={styles.right} />
    </div>
  );
};

export const BusinessDisambiguatorAddNewBusiness: React.FC = () => {
  const { mutate: createNewBusiness, isPending } = useCreateNewBusiness();

  return (
    <div
      role="button"
      tabIndex={0}
      className={styles.business}
      onClick={() => createNewBusiness()}
      aria-disabled={isPending}
    >
      {isPending ? (
        <AnimatedSpinner size={24} color={colors.purple[500]} />
      ) : (
        <PlusCircle size={24} color={colors.purple[500]} />
      )}
      <div>
        <Text size={14} color={colors.purple[500]}>
          Add new business
        </Text>
      </div>
    </div>
  );
};
