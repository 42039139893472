import env from "env";
import { useState } from "react";
import { useSetRecoilState } from "recoil";
import UserExistenceRep from "reps/UserExistenceRep";
import UserInvitationRep from "reps/UserInvitationRep";
import useSignUpWithRedirect from "resources/auth/hooks/useSignUpWithRedirect";
import businessGuidState from "state/auth/businessGuid";
import Button from "ui/inputs/Button";
import TextLink from "ui/navigation/TextLink";
import Text from "ui/typography/Text";

import styles from "./Invitation.module.scss";

type Props = {
  invitation: UserInvitationRep.Complete;
  userExistence: UserExistenceRep;
};

const Invitation: React.FC<Props> = ({ invitation, userExistence }) => {
  const signUpWithRedirect = useSignUpWithRedirect({
    screenHint: userExistence.exists ? "login" : "signup",
  });

  const setBusinessGuid = useSetRecoilState(businessGuidState);

  const [ctaPending, setCtaPending] = useState(false);

  function acceptInvite() {
    setCtaPending(true);

    // Switching to the invitation business guid so that it gets auto-selected after acceptance.
    setBusinessGuid(invitation.businessGuid);

    signUpWithRedirect({
      // eslint-disable-next-line camelcase
      login_hint: invitation.emailAddress,
      userInvitationSlug: invitation.slug,
    });
  }

  return (
    <div className={styles.container}>
      <div className={styles.main}>
        <div className={styles.brand} style={{ display: "none" }}>
          <div className={styles.avatar} />
          <div className={styles.logo} />
        </div>

        <div className={styles.text}>{invitationMessage(invitation)}</div>
        <Button
          variant="primary"
          className={styles.button}
          onClick={acceptInvite}
          disabled={ctaPending}
        >
          Accept invite
        </Button>
      </div>
    </div>
  );
};

export default Invitation;

function invitationMessage(invitation: UserInvitationRep.Complete) {
  return (
    <>
      <Text size={20} weight="bold" className={styles.primary}>
        {invitation.senderName} invited you to{" "}
        {invitation.businessDisplayName ? (
          <>access the Highbeam account for {invitation.businessDisplayName}</>
        ) : (
          <TextLink external to={env.HIGHBEAM_MARKETING_SITE_URL}>
            Highbeam
          </TextLink>
        )}
        .
      </Text>
      <Text size={16} className={styles.secondary}>
        Once you accept the invite, you will be able to access their Highbeam account.
      </Text>
    </>
  );
}
